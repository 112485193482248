import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const NewsLetterApi = createApi({
  reducerPath: 'newsLetter',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['newsLetter'],
  endpoints: (builder) => ({
    // SENDING NEWS LETTER
    newsLetter: builder.mutation({
      query: ({ email }) => ({
        url: `emailNewsLetter`,
        method: 'POST',
        body: {
          email,
        },
      }),
      transformResponse: (res) => res,
      providesTags: ['newsLetter'],
    }),
  }),
});

export const { useNewsLetterMutation } = NewsLetterApi;
