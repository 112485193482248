import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes

import appLogo from './appLogo.png';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, href, sx, ...other }, ref) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const is_dashboard = pathname.includes('admin');

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 60,
        height: 70,
        marginTop: '.5rem',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img src={appLogo} alt="applogo" width="1000" />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <>
      {is_dashboard ? (
        <>
          <Link component={RouterLink} href={href} sx={{ display: 'contents' }}>
            {logo}
          </Link>
        </>
      ) : (
        <>
          <HashLink smooth to={href} style={{ textDecoration: 'none' }}>
            <Link sx={{ display: 'contents' }}>{logo}</Link>
          </HashLink>
        </>
      )}
    </>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
