import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const ContactApi = createApi({
  reducerPath: 'contact',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['contacts'],
  endpoints: (builder) => ({

    // SENDING CONTACT QUERY
    sendContactQuery: builder.mutation({
      query: ({ name, email, phoneNumber, description }) => ({
        url: `contactUs`,
        method: 'POST',
        body: {
          name,
          email,
          phoneNumber,
          description,
        },
      }),
      transformResponse: (res) => res,
      providesTags: ['contacts'],
    }),
  }),
});

export const { useSendContactQueryMutation } = ContactApi;
