import React from 'react'
import AboutWhatTsc from '../about-what-tcs'
import AboutTsc from '../about-hero-tsc'
// import { useGetTacQuery } from 'src/store/Reducer/settings'
// import { useSettingsContext } from 'src/components/settings';

export default function TACView() {
  // const { data } = useGetTacQuery();
  const data = {}

  return (
    <>
      <AboutTsc data={{ title: "Our", list: ['Terms And Conditions'] }} />
      <AboutWhatTsc data={{ title: "", content: data?.body?.tac, image: '/assets/images/about/Terms_and_conditions.png' }} />
    </>
  )
}
