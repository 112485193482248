import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
// BITMOJI
const BitmojiListPage = lazy(() => import('src/pages/dashboard/profile-bitmojis/list'));
const BitmojiCreatePage = lazy(() => import('src/pages/dashboard/profile-bitmojis/new'));
const SubscriptionSettingsCreatePage = lazy(() =>
  import('src/pages/dashboard/subscription-settings/new')
);
const FeelingsListPage = lazy(() => import('src/pages/dashboard/feelings/list'));
const ContactSupportListPage = lazy(() => import('src/pages/dashboard/contact-support/list'));
const ContactUstListPage = lazy(() =>
  import('src/pages/dashboard/contact-support/contact-us-list')
);
const PregnancyJourneyListPage = lazy(() =>
  import('src/pages/dashboard/pregnancy-journey/list-pregnancy-journey')
);
const ConceptionMethodListPage = lazy(() =>
  import('src/pages/dashboard/pregnancy-journey/list-conception-method')
);
const WeekOfPregnancyListPage = lazy(() =>
  import('src/pages/dashboard/pregnancy-journey/list-week-of-pregnancy')
);
const NumberOfLossesListPage = lazy(() =>
  import('src/pages/dashboard/loss-details/list-number-of-losses')
);
const PreganancyLossListPage = lazy(() =>
  import('src/pages/dashboard/loss-details/list-pregnancy-loss')
);
const YearSinceLossListPage = lazy(() =>
  import('src/pages/dashboard/loss-details/list-year-since-loss')
);
const AdditionalSupportListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-additional-support')
);
const CurrentHealingPhaseListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-current-healing-phase')
);
const EmotionalStatusListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-emotional-status')
);
const PreganancyPlanningStatusListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-pregnancy-planning')
);
const EmailNewsLetterListPage = lazy(() => import('src/pages/dashboard/email-news-letter/list'));
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const PrivacyPage = lazy(() => import('src/pages/dashboard/privacy'));
const TermsAndConditionsPage = lazy(() => import('src/pages/dashboard/tac'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'privacy-policy', element: <PrivacyPage /> },
      { path: 'terms-conditions', element: <TermsAndConditionsPage /> },

      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'profile/:id', element: <UserProfilePage /> },
          { path: 'new', element: <UserCreatePage /> },
        ],
      },
      {
        path: 'bitmoji',
        children: [
          { element: <BitmojiListPage />, index: true },
          { path: 'list', element: <BitmojiListPage /> },
          { path: 'new', element: <BitmojiCreatePage /> },
        ],
      },

      {
        path: 'subscription-setting',
        children: [
          { element: <SubscriptionSettingsCreatePage />, index: true },
          { path: 'new', element: <SubscriptionSettingsCreatePage /> },
        ],
      },

      {
        path: 'feelings',
        children: [
          { element: <FeelingsListPage />, index: true },
          { path: 'list', element: <FeelingsListPage /> },
        ],
      },
      {
        path: 'contact-support',
        children: [
          { element: <ContactSupportListPage />, index: true },
          { path: 'list', element: <ContactSupportListPage /> },
        ],
      },
      {
        path: 'email-news-letter',
        children: [{ element: <EmailNewsLetterListPage />, index: true }],
      },
      { path: 'contact-us-list', element: <ContactUstListPage /> },
      {
        path: 'Pregnancy-Journey',
        children: [
          { element: <PregnancyJourneyListPage />, index: true },
          { path: 'list-pregnancy-journey', element: <PregnancyJourneyListPage /> },
          { path: 'list-conception-method', element: <ConceptionMethodListPage /> },
          { path: 'list-weeks-of-pregnancy-until-loss', element: <WeekOfPregnancyListPage /> },
        ],
      },
      {
        path: 'loss-details',
        children: [
          { element: <PreganancyLossListPage />, index: true },
          { path: 'list-pregnancy-loss', element: <PreganancyLossListPage /> },
          { path: 'list-year-since-loss', element: <YearSinceLossListPage /> },
          { path: 'list-number-of-losses', element: <NumberOfLossesListPage /> },
        ],
      },
      {
        path: 'healing-journey',
        children: [
          { element: <CurrentHealingPhaseListPage />, index: true },
          { path: 'list-current-healing-phase', element: <CurrentHealingPhaseListPage /> },
          { path: 'list-emotional-status', element: <EmotionalStatusListPage /> },
          { path: 'list-pregnancy-planning-status', element: <PreganancyPlanningStatusListPage /> },
          { path: 'list-additional-support', element: <AdditionalSupportListPage /> },
        ],
      },

      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
