import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const supportApi = createApi({
  reducerPath: 'support',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Contact-Us'],
  endpoints: (builder) => ({
    getAllSupport: builder.query({
      query: (data) => {
        let url = '/support';
        if (data) {
          const { page, limit, keyword, status } = data;
          let pages = page + 1;
          if (pages && limit) {
            url += `?page=${page + 1}&limit=${limit}`;
          }
          if (keyword) {
            url += `&keyword=${keyword}`;
          }
          if (status) {
            url += `&status=${status}`;
          }
          if(data?.statuses){
            url += `?status=${data.statuses}`;
          }
        }
        return url;
      },
      transformResponse: (res) => res,
      providesTags: ['Contact-Us'],
    }),

    getAllContactUs: builder.query({
      query: (data) => {
        let url = '/contactUs';
        if (data) {
          const { page, limit, keyword, status } = data;
          let pages = page + 1
          if (pages && limit) {
            url += `?page=${page + 1}&limit=${limit}`;
          }
          if (keyword) {
            url += `&keyword=${keyword}`;
          }
          if (status) {
            url += `&status=${status}`;
          }
          if(data?.statuses){
            url += `?status=${data.statuses}`;
          }
        }
        return url;
      },
      transformResponse: (res) => res,
      providesTags: ['Contact-Us'],
    }),

    sendReply: builder.mutation({
      query: (data) => ({
        url: `support/reply`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Contact-Us'],
    }),

    updateContactStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `contactUs/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Contact-Us'],
    }),

    updateSupportStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `support/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Contact-Us'],
    }),

    getAllEmailNewsLetter: builder.query({
      query: (data) => {
        let url = `/emailNewsLetter`;
        if (data) {
          const { page, limit } = data;
          url += `?page=${page + 1}&limit=${limit}`;
        }
        return url;
      },
      transformResponse: (res) => res,
      providesTags: ['Contact-Us'],
    }),
  }),
});

export const {
  useGetAllSupportQuery,
  useGetAllContactUsQuery,
  useSendReplyMutation,
  useUpdateContactStatusMutation,
  useUpdateSupportStatusMutation,
  useGetAllEmailNewsLetterQuery,
  useLazyGetAllContactUsQuery,
  useLazyGetAllSupportQuery,
  useLazyGetAllEmailNewsLetterQuery,
} = supportApi;
