// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// routes

import { RouterLink } from 'src/routes/components';
// _mock
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { InputAdornment } from '@mui/material';
// import { EmailInboxIcon } from 'src/assets/icons';
import { enqueueSnackbar } from 'notistack';
import { useNewsLetterMutation } from 'src/store/Reducer/newsLetter';
import { LoadingButton } from '@mui/lab';
import { HashLink } from 'react-router-hash-link';

// ----------------------------------------------------------------------

const SOCIALS = [
  // { name: 'Facebook', href: '' },
  // { name: 'Apple', href: '' },
  // { name: 'Google', href: '' },
];

const LINKS = [
  {
    headline: 'Angelsbond',
    children: [
      // { name: 'About us', href: paths.about },
      { name: 'About Us', href: 'AboutUs' },
      // { name: 'Subscriptions', href: 'Subscriptions' },
      {
        name: 'Download App',
        href: 'Home',
      },
      { name: 'Contact Us', href: 'Contact Us' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      // { name: 'FAQs', href: paths.faqs },
      { name: 'Privacy Policy', href: '/privacy-policy' },
      { name: 'Terms & Conditions', href: '/tac' },
      // { name: "Terms of Use", href: "/privacy-policy" },
    ],
  },
  // {
  //   headline: "Find Us",
  //   children: [
  //     { name: "Facebook", href: "#" },
  //     { name: "Apple", href: "#" },
  //     { name: "Google", href: "#" },
  //   ],
  // },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const [sendNewsLetter] = useNewsLetterMutation();

  const emailSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const methods = useForm({
    resolver: yupResolver(emailSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // console.log(values);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const newData = {
        email: data.email,
      };


      const response = await sendNewsLetter(newData);

      if (!response.error) {
        enqueueSnackbar(response?.data?.message || 'Success!');
        reset();
        setValue('email', '');
      }
    } catch (error) {
      console.error(error);
    }
  });

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        // background: 'linear-gradient(to bottom right, #141111 50%, #13AC96 100%)',
        background: 'linear-gradient(to bottom right, #141111 50%, #13AC96)',
        color: 'white', // set text color to white
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Box sx={{ display: 'inline-flex', alignItems: 'end', mb: 3 }}>
          <Logo href='/#Home' />
          <Typography
            variant="h6"
            sx={{
              marginLeft: '.5rem',
              background: 'linear-gradient(to right, #13AC96, #8173A3)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            AngelsBond
          </Typography>
        </Box>

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={12} md={5}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Enter your email address to get updates on daily basis.
            </Typography>

            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Grid
                item
                spacing={0}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                }}
              >
                <RHFTextField
                  name="email"
                  // label="Email"
                  variant="outlined"
                  placeholder="Enter your email address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="mdi:email-outline" width={28} color="#13AC96" />
                      </InputAdornment>
                    ),
                    sx: {
                      color: '#fff',
                      borderRadius: '1rem 0 0 1rem',
                      backgroundColor: 'tranparent',
                    },
                  }}
                />

                <LoadingButton
                  color="primary"
                  size="large"
                  // sx={{ minWidth: '10rem', marginTop: '1rem' }}
                  style={{
                    borderRadius: '0 1rem 1rem 0',
                    height: '100%',
                    minHeight: '53px',
                    width: '10rem',
                    backgroundColor: '#13AC96',
                    // '&:hover': {
                    //   backgroundColor: '#6C63FF',
                    // },
                  }}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </FormProvider>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {SOCIALS?.map((v) => (
                <IconButton aria-label={v.name} sx={{ mr: 1 }}>
                  <Link href={v.href} target="_blank" rel="noopener" underline="none">
                    <Iconify color="#fafafa" icon={`ri:${v.name?.toLowerCase()}-fill`} width={30} />
                  </Link>
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            {/* <Stack spacing={5} direction={{ xs: "column", md: "row" }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: "center", md: "flex-start" }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        smoothScrollTo(link.href);
                      }}
                      key={link.name}
                      component={RouterLink}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack> */}

            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div">{list.headline}</Typography>

                  {/* Stack for home page section links */}
                  {list.headline === 'Angelsbond' &&
                    list.children.map((link) => {
                      return (
                        <>
                          {link.name === 'Download App' ? (
                            <>
                              {/* <a
                                href={link.href} // Provide the Play Store/App Store URL here
                                target="_blank" // Opens in a new window
                                rel="noopener noreferrer" // Ensures security
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                  cursor: 'pointer',
                                }}
                              >
                                {link.name}
                              </a> */}

                              <HashLink
                                smooth
                                to={`/#Home`}
                                key={link.name}
                                component={RouterLink}
                                color="inherit"
                                variant="body2"
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                              >
                                {link.name}
                              </HashLink>
                            </>
                          ) : (
                            <>
                              <HashLink
                                smooth
                                to={`/#${link.name.replace(/\s+/g, '')}`}
                                key={link.name}
                                component={RouterLink}
                                color="inherit"
                                variant="body2"
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}
                              >
                                {link.name}
                              </HashLink>
                            </>
                          )}
                        </>
                      );
                    })}

                  {/* Stack for external links */}
                  {list.headline === 'Legal' &&
                    list.children.map((link) => (
                      <Link
                        key={link.name}
                        component={RouterLink}
                        href={link.href}
                        color="inherit"
                        variant="body2"
                        sx={{ color: 'white' }}
                      >
                        {link.name}
                      </Link>
                    ))}

                  {/* Stack for "Find Us" social links */}
                  {/* {list.headline === 'Find Us' &&
                    list.children.map((social) => (
                      <Link
                        key={social.name}
                        href={social.href}
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                        variant="body2"
                        sx={{ color: 'white' }}
                      >
                        {t(social.name)}
                      </Link>
                    ))} */}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
}
