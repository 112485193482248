import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from 'src/layouts/main';
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';


// ----------------------------------------------------------------------

export default function Router() {


  return useRoutes([
    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    // Auth routes
    ...authRoutes,
    // ...authDemoRoutes,
    //  Dashboard routes
    ...dashboardRoutes,


    //super-admin dashboard
    // ...superadmindashboardRoutes,

    //user-dashboard
    // ...admindashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,



    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
