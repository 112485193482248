// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { MotionViewport } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useGetTacQuery } from 'src/store/Reducer/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import parse from 'html-react-parser';


// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Partners', 'Customer', 'Booking'][index],
  value: [55, 40, 20][index],
}));

// ----------------------------------------------------------------------

export default function AboutWhatTsc() {
  const theme = useTheme();

  const { data, isLoading } = useGetTacQuery();
  console.log("data :" ,data)

  const mdUp = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const settings = useSettingsContext();

  const { t, currentLang } = useLocales();

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  
  const htmlString = data?.terms_and_conditions || `<strong>AngelsBond</strong>`;

  if (isLoading) return <LoadingScreen />;

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 3, md: 6 },
        textAlign: { xs: 'start', md: 'unset' },
      }}
    >
      <Grid
        sx={{ direction: 'ltr' }}
        flexDirection={
          currentLang?.label === 'Arabic' || currentLang?.label === 'Urdu' ? 'row-reverse' : 'row'
        }
        container
        alignItems="flex-start"
      >
        {/* <Grid container xs={12} md={6} lg={7} alignItems="center" sx={{ p: { md: 3 } }}>
          <Grid xs={12}>
            <m.div variants={varFade().inUp}>
              <Image
                alt="our office 2"
                src={data?.image}
                ratio="1/1"
                sx={{ borderRadius: 3 }}
              />
            </m.div>
          </Grid>
        </Grid> */}

        <Grid xs={12} md={12} lg={12}>
          <Typography  sx={{ mb: 1, mt: 2 }}>
            {parse(`${htmlString}`)}

          </Typography>

          {/* </m.div> */}
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}

// <div
//    style={{ direction: 'ltr' }}
//    dangerouslySetInnerHTML={{
//      __html: data?.content ? `${data?.content}` : `${t('Loading...')}`,
//    }}
//  />
