import { m } from 'framer-motion';
// @mui
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
// components
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { useGetPrivacyQuery } from 'src/store/Reducer/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import parse from 'html-react-parser';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Partners', 'Customer', 'Booking'][index],
  value: [55, 40, 20][index],
}));

// ----------------------------------------------------------------------

export default function AboutWhatPrivacy() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');
  const { data, isLoading } = useGetPrivacyQuery();



  const {  currentLang } = useLocales();


  const htmlString = data?.privacy_policy || `<strong>AngelsBond</strong>`;

  if (isLoading) return <LoadingScreen />;
  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 3, md: 6 },
        textAlign: { xs: 'start', md: 'unset' },
      }}
    >
      <Grid
        sx={{ direction: 'ltr' }}
        flexDirection={
          currentLang?.label === 'Arabic' || currentLang?.label === 'Urdu' ? 'row-reverse' : 'row'
        }
        container
        alignItems="flex-start"
      >
     

        <Grid xs={12} md={12} lg={12}>
          <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
            Privacy Policy for AngelsBond
          </Typography>

          <m.div variants={varFade().inRight}>
            <Typography
              // sx={{
              //   color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
              // }}
            >
              {parse(`${htmlString}`)}
            </Typography>
          </m.div>

        
      
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}

// <div
//    style={{ direction: 'ltr' }}
//    dangerouslySetInnerHTML={{
//      __html: data?.content ? `${data?.content}` : `${t('Loading...')}`,
//    }}
//  />
