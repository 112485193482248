import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { CardMedia } from '@mui/material';

export default function DownloadButtons(props) {

    const handleClick = () => {
        if (props.url) {
            window.open(props.url, '_blank', 'noopener,noreferrer');
        } else {
            console.error('No URL provided for Download Button');
        }
    };

    return (
        <Box sx={{ width: '180px', padding: '0px', zIndex: 1  }}>
            <Card
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    backgroundColor: props.backgrounColor,
                    justifyContent: 'center',
                    boxShadow: 5,
                    padding: '.6rem 0rem',
                    textAlign: 'center',
                    height: props.height,
                }}
                onClick={handleClick} // Navigate to the provided URL on click
            >
                <CardMedia
                    sx={{
                        marginRight: '.4rem',
                        width: '28px',
                        height: '28px',
                        color: '#FFFFFF',
                    }}
                    image={props.image}
                    title="Play Store"
                />

                <Typography
                    variant="body1"
                    sx={{
                        minWidth: "100px",
                        color: props.color,
                        fontSize: {
                            xs: '.9rem',
                        },
                    }}
                >
                    {props.description}
                    <br />
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '1rem',
                            },
                        }}
                        variant="h5"
                        component="span"
                    >
                        <b>{props.title}</b>
                    </Typography>
                </Typography>
            </Card>
        </Box>
    );
}
