import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
);

const ICONS = {
  job: icon('ic_job'),
  pregnancyJourney: icon('ic_pregnancyJourney'),
  lostDetails: icon('ic_lostDetails'),
  healingJourney: icon('ic_healingJourney'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  locck: icon('ic_lock'),
  feelings: icon('ic_feelings'),
  settings: <SvgColor src={`/assets/icons/setting/ic_setting.svg`} sx={{ width: 1, height: 1 }} />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('Support'),
            path: paths.dashboard.contactSupport.root,
            icon: <Icon icon="material-symbols:contact-phone" fontSize={20} />,
          },
          {
            title: t('Contact Us'),
            path: paths.dashboard.contactSupport.contactUsList,
            icon: <Icon icon="ion:mail" fontSize={20} />,
          },
          {
            title: t('Email News Letter'),
            path: paths.dashboard.emailNewsLetter.emailNewsLetterList,
            icon: <Icon icon="oui:email" fontSize={18} />,
          },
          {
            title: t('chat'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          // USER
          {
            title: t('Users'),
            path: paths.dashboard.user.root,
            icon: <Icon icon="fa-solid:users" fontSize={20} />,
          },
          {
            title: t('Profile Bitmojis'),
            path: paths.dashboard.bitmoji.root,
            icon: <Icon icon="solar:gallery-add-bold" fontSize={20} />,
          },
          {
            title: t('Feelings'),
            path: paths.dashboard.feelings.root,
            icon: ICONS.feelings,
          },
          {
            title: t('Pregnancy Journey'),
            path: paths.dashboard.pregnancyJourney.root,
            icon: <Icon icon="zondicons:travel" fontSize={20} />,
            children: [
              {
                title: t('Type of Pregnancy Journey'),
                path: paths.dashboard.pregnancyJourney.pregnancyJourneyList,
              },
              {
                title: t('Conception Method'),
                path: paths.dashboard.pregnancyJourney.conceptionMethodList,
              },
              {
                title: t('Weeks of Pregnancy Until Loss'),
                path: paths.dashboard.pregnancyJourney.weekOfPregnancyList,
              },
            ],
          },
          {
            title: t('Loss Details'),
            path: paths.dashboard.lossDetails.root,
            icon: ICONS.lostDetails,
            children: [
              {
                title: t('Type of Pregnancy Loss'),
                path: paths.dashboard.lossDetails.pregnancyLoss,
              },
              {
                title: t('Year Since Loss'),
                path: paths.dashboard.lossDetails.yearSinceLoss,
              },
              {
                title: t('Number of Losses'),
                path: paths.dashboard.lossDetails.numberOfLosses,
              },
            ],
          },
          {
            title: t('Healing Journey'),
            path: paths.dashboard.healingJourney.root,
            icon: ICONS.healingJourney,
            children: [
              {
                title: t('Current Healing Phase'),
                path: paths.dashboard.healingJourney.CurrentHealingPhaseList,
              },
              {
                title: t('Emotional Status'),
                path: paths.dashboard.healingJourney.EmotionalStatusList,
              },
              {
                title: t('Pregnancy Planning Status'),
                path: paths.dashboard.healingJourney.PreganancyPlanningStatusList,
              },
              {
                title: t('Additional Support'),
                path: paths.dashboard.healingJourney.AdditionalSupport,
              },
            ],
          },

          {
            title: t('Privacy Policy'),
            icon: ICONS.lock,
            path: paths.dashboard.privacyPolicy.root,
          },
          {
            title: t('Terms & Conditions'),
            icon: <Icon icon="ls:file" fontSize={20} />,
            path: paths.dashboard.termsAndConditions.root,
          },
          {
            title: t('Subscription Settings'),
            icon: <Icon icon="jam:crown-f" fontSize={20} />,
            path: paths.dashboard.subscription_setting.new,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
